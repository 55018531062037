<template>
  <a-config-provider :locale="locale">


    <!--    <div v-if="isAdminUser !== ''">-->
      <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="质检工单">
        <Record/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据统计">
        <statistics/>
      </a-tab-pane>
      
    </a-tabs>



  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Record from "@/views/open/workOrder/Record.vue";
import statistics from "@/views/open/workOrder/statistics.vue";


export default {
  name: "index",
  components: {Record,statistics},
  setup(props, context) {
    let locale = zhCN;

    return {
      locale,
    }
  }
}
</script>

<style scoped>

</style>